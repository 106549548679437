<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button align="right" @click="createOrderHandler" icon-pack="feather" icon="icon-edit">Create Order
        </vs-button>
      </div>
    </div>
    <OrdersList :orderData="orderData" :noDataText="noDataText" @fetchList="getOrdersList"
      @viewOrderDetail="viewOrderDetail" @regenerateAttachment="generatePDF" :hasNext="hasNext"
      :displayAll="true" :hasPrev="hasPrev"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import OrdersList from "../../../components/WebStore/orders/OrdersList";

export default {
  components: {
    OrdersList,
  },
  data() {
    return {
      hasNext: false,
      hasPrev: false,
      orderData: [],
      noDataText: "Loading...",
    };
  },
  methods: {
    ...mapActions("storeOrder", ["regenerateOrderAttachment", "getPaginatedOrderList"]),
    async getOrdersList(dataTableParams) {
      let self = this;
      this.orderData = []
      this.noDataText = "Loading..."
      await this.getPaginatedOrderList(dataTableParams).then(async (res) => {
        self.orderData = res.data.data.docs;
        self.hasNext = res.data.data.hasNext;
        self.hasPrev = res.data.data.hasPrev;
        self.noDataText = "No Orders Available";
      });
    },
    viewOrderDetail(id) {
      this.$router.push({
        name: "superAdminStoreViewOrder",
        params: { orderId: id },
      });
    },
    createOrderHandler() {
      this.$router.push({ name: "superAdminStoreCreateOrder" });
    },
    async generatePDF(id) {
      this.$vs.loading();

      this.regenerateOrderAttachment(id).then((res) => {
        const result = res.data.data;

        this.orderData = [...this.orderData].map((val) => {
          if (val._id && result.orderId && (val._id.toString() === result.orderId.toString())) {
            return {
              ...val,
              orderinvoices: {
                ...val.orderinvoices,
                pdfLink: result.pdfLink
              }
            }
          }

          return { ...val }
        })

        if (res.status === 200) {
          this.$vs.notify({
            title: "Invoice PDF generated succesfully",
            text: "Please reload the page",
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }

        this.$vs.loading.close();
      });
    },
  },
};
</script>
